import React, { useState } from "react"
import styled from "styled-components"
import SquereImg from "./SquereImg"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Title = styled.h3`
  font-family: var(--fontFamily-text);
  text-transform: none;
  font-size: 2.2rem;
  letter-spacing: 0.26rem;
  font-weight: 400;
  margin: 1rem 0 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Localization = styled.p`
  color: var(--grey);
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
`

const StyledLink = styled(AniLink)`
  &:hover,
  &:focus {
    img {
      transform: scale(1.05);
    }
  }
`

const ProjectPostLink = ({
  title,
  imgSrc,
  imgBigDivider = 2,
  localization,
  slug,
  className,
  currentPathname = "",
  onClick = null,
}) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <StyledLink
      to={slug}
      cover
      direction="bottom"
      bg="#fdfdfe"
      state={{ previousPathname: currentPathname }}
      onClick={() => {
        window.sessionStorage.setItem("backUrl", currentPathname)
        onClick && onClick()
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <article className={className}>
        <SquereImg
          src={imgSrc}
          alt={`Zdjęcie projektu ${title.toLowerCase()}`}
          imgBigDivider={imgBigDivider}
          isHover={isHover}
        />
        <Title>{title}</Title>
        <Localization className="additional-info">{localization}</Localization>
      </article>
    </StyledLink>
  )
}

export default ProjectPostLink
