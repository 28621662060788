import React, { useState, useRef, useEffect } from "react"

const ResponsiveImage = ({
  src,
  alt,
  bigDivider,
  isFullWidth = false,
  onLoadCallback = null,
}) => {
  const [splitSrc] = useState(src.split("w_auto"))
  const image = useRef(null)
  const loaded = useRef(false)

  let handleLoad = () => {
    onLoadCallback && onLoadCallback()
  }

  useEffect(() => {
    if (image.current.complete && !loaded.current) {
      loaded.current = true
      handleLoad()
    }
  }, [])

  return (
    <img
      alt={alt}
      onLoad={() => !loaded.current && handleLoad()}
      ref={image}
      src={src}
      srcSet={`
            ${splitSrc.join("w_400")} 400w,
            ${splitSrc.join("w_600")} 600w,
            ${splitSrc.join("w_800")} 800w,
            ${splitSrc.join("w_1000")} 1000w,
            ${splitSrc.join("w_1500")} 1500w,
            ${splitSrc.join("w_2000")} 2000w,
        `}
      sizes={`
            ${
              !isFullWidth
                ? `(min-width: 1360px) calc(1500px/${bigDivider}),`
                : ""
            }
            (min-width: 992px) calc(100vw/${bigDivider}),
            100vw
        `}
    />
  )
}

export default ResponsiveImage
