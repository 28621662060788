import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import FlipMove from "react-flip-move"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"

import ContactSection from "../components/ContactSection"
import Layout from "../components/layout"
import ProjectPostLink from "../components/ProjectPostLink"
import CrossIcon from "../components/crossIcon"
import SEO from "../components/seo"

import { setScrollAnimation } from "../helpers/animations"

import { BorderContainerBox } from "./o_nas"

const ProjectPostLinkContainer = styled.div`
  margin-bottom: var(--base-grid-gutter);
`
const StyledBorderContainerBox = styled(BorderContainerBox)`
  margin-bottom: 6rem;
  margin-top: 8rem;

  p {
    margin-bottom: 0;
  }
`

const Tags = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media only screen and (min-width: 62em) {
    flex-direction: row;
    align-items: center;
    flex-basis: unset;
    max-width: unset;
  }
`

const FilterButton = styled.a`
  border: none;
  background-color: transparent;
  outline: none;
  position: relative;
  transition: padding ease-in-out 0.2s;
  padding-right: 1.8rem;
  margin: 0 0 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 62em) {
    margin: 0 2rem 0 0;
  }

  span {
    display: inline-block;
    transition: transform ease-in-out 0.2s;
  }

  &:hover {
    span {
      transform: scale(0.97);
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: -1px;
    bottom: -3px;
    width: 0%;
    height: 1px;
    background-color: var(--grey);
    transition: width ease-in-out 0.2s;
  }

  ${({ active }) =>
    active &&
    `
    &::after {
      width: 100%;
    }
  `}
`

const StyledCrossIcon = styled(CrossIcon)`
  stroke: var(--grey);
  width: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity ease-in-out 0.2s;

  ${({ active }) =>
    active &&
    `
    opacity: 1;
  `}
`

const Projekty = ({ location, data }) => {
  gsap.registerPlugin(ScrollTrigger)

  const [currentTag, setCurrentTag] = useState({ ...data.currentTag })
  const [filteredProjects, setFilteredProjects] = useState([])
  let [locoScroll, setLocoScroll] = useState(null)

  const clearScrollAnimation = useRef(null)
  const sectionsRefs = useRef([])
  sectionsRefs.current = []

  const addToSectionsRefs = el => {
    if (el && !sectionsRefs.current.includes(el)) {
      sectionsRefs.current.push(el)
    }
  }

  const goToTagPage = (event, tag) => {
    event.preventDefault()
    const href = event.target.href || event.target.parentElement.href
    window.history.pushState({}, "", href)
    setCurrentTag(currentTag?.id === tag.id ? null : tag)
  }

  const addLocomotiveScripts = () => {
    let localLocoScroll

    localLocoScroll = new window.LocomotiveScroll({
      el: document.querySelector(".scroll-container"),
      smooth: true,
      scrollFromAnywhere: true,
      reloadOnContextChange: true,
    })

    setLocoScroll(localLocoScroll)

    clearScrollAnimation.current = setScrollAnimation(
      localLocoScroll,
      sectionsRefs
    )
  }

  useEffect(() => {
    let projects = [...data.projects.edges]

    if (currentTag?.frontmatter?.tagName) {
      projects = projects.filter(({ node: { frontmatter: { tags } } }) =>
        tags.includes(currentTag.frontmatter.tagName)
      )
    }

    setFilteredProjects(projects)

    if (locoScroll) {
      setTimeout(() => {
        locoScroll.update()
        clearScrollAnimation.current()
        clearScrollAnimation.current = setScrollAnimation(
          locoScroll,
          sectionsRefs
        )
      }, 750)
    }
  }, [currentTag, locoScroll])

  useEffect(() => {
    return () => {
      clearScrollAnimation.current()
    }
  }, [])
  return (
    <Layout
      location={location}
      locoScroll={locoScroll}
      className="scroll-container"
      addLocomotiveScripts={addLocomotiveScripts}
    >
      <SEO
        title={`Bui-Pędowska Architekci - Projekty ${
          currentTag?.frontmatter?.tagName || ""
        }`}
        description="Projekty budynków mieszkalnych jedno- i wielorodzinnych, biurowych oraz użyteczności publicznej"
      />
      <section className="container">
        <div className="row section-with-nav-content">
          <StyledBorderContainerBox className="col-xs-12">
            <h2 className="col-xs-5 col-md-2">Projekty</h2>
            <Tags className="col-xs-7">
              {data.tags.nodes.map(tag => {
                const isCurrentTag = currentTag?.id === tag.id
                return (
                  <FilterButton
                    className="additional-info"
                    href={isCurrentTag ? `/projekty/` : `/${tag.fields.slug}`}
                    key={tag.id}
                    active={isCurrentTag}
                    rype="button"
                    onClick={event => goToTagPage(event, tag)}
                  >
                    <span>{tag.frontmatter.tagName}</span>
                    <StyledCrossIcon active={isCurrentTag} />
                  </FilterButton>
                )
              })}
            </Tags>
          </StyledBorderContainerBox>
        </div>
        <FlipMove className="row" duration={500}>
          {filteredProjects.map(
            ({
              node: {
                id,
                frontmatter: { title, main_image, localization },
                fields: { slug },
              },
            }) => (
              <ProjectPostLinkContainer
                className="col-xs-12 col-md-3"
                key={id}
                sectionsRefs={sectionsRefs}
              >
                <div ref={addToSectionsRefs}>
                  <ProjectPostLink
                    key={id}
                    title={title}
                    imgSrc={main_image}
                    imgBigDivider={3}
                    localization={localization}
                    slug={slug}
                    currentPathname={`/${
                      currentTag?.fields?.slug || "projekty/"
                    }`}
                    onClick={() => locoScroll && locoScroll.destroy()}
                  />
                </div>
              </ProjectPostLinkContainer>
            )
          )}
        </FlipMove>
      </section>
      <ContactSection />
    </Layout>
  )
}

export default Projekty
export const pageQuery = graphql`
  query ProjectQuery($id: String) {
    projects: allMarkdownRemark(
      filter: { frontmatter: { layout: { ne: "tags" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            main_image
            title
            localization
            tags
          }
          fields {
            slug
          }
        }
      }
    }
    currentTag: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        tagName
      }
    }
    tags: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "tags" } } }
    ) {
      nodes {
        id
        frontmatter {
          tagName
        }
        fields {
          slug
        }
      }
    }
  }
`
