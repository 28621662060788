import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import { gsap } from "gsap"

import ResponsiveImage from "./ResponsiveImage"

const StyledImgContainer = styled.div`
  height: ${({ height }) => height}px;
  max-height: 90vw;
  overflow: hidden;

  img {
    width: 100%;
    min-height: calc(100% + 50px);
    object-fit: cover;
    transform: translateY(-25px);
    transition: transform 0.2s ease;
  }
`

const SquereImg = ({ src, alt = "", imgBigDivider = 1, isHover = false }) => {
  const imgEl = useRef(null)
  const [height, setHeight] = useState(400)
  const [hoverTl] = useState(
    gsap.timeline({
      paused: true,
      defaults: {
        ease: "back",
        transformOrigin: "center center",
        duration: 0.3,
      },
    })
  )

  useEffect(() => {
    isHover ? hoverTl.play() : hoverTl.reverse()
  }, [isHover])

  useEffect(() => {
    setHeight(imgEl?.current?.offsetWidth || 400)

    hoverTl.fromTo(
      imgEl?.current?.querySelector("img"),
      { scale: 1 },
      {
        scale: 1.07,
      }
    )
  }, [])

  return (
    <StyledImgContainer ref={imgEl} height={height} className="image-conatiner">
      <ResponsiveImage src={src} alt={alt} bigDivider={imgBigDivider} />
    </StyledImgContainer>
  )
}

export default SquereImg
